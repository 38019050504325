import AzkSelect from "_azkatech/azkSelect";
import axios, { AxiosResponse } from "axios";
import * as Yup from 'yup'
import { SalaryStructure, SalaryStructureDetail, initialTransaction } from "./model";
import { FC, useEffect, useRef, useState } from "react";
import { ErrorMessage, Formik, FormikProps, FormikValues } from 'formik'
import { CreateSalaryStructure, UpdateSalaryStructure, GetSalaryStructureById, MODEL_NAME, GetSalaryStructureByFilter, DeleteSalaryStructure } from "./_requests";
import moment from "moment";
import * as authHelper from 'app/modules/auth/core/AuthHelpers'
import "react-datepicker/dist/react-datepicker.css";
import { AzkStatusRibbon } from "_azkatech/azkStatusRibbon";
import AzkActionButtons from "_azkatech/azkActionButtons";
import { Form, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useIntl } from "react-intl";
import { ID, isNotEmpty, KTIcon, Response } from "_metronic/helpers";
import { useNavigate } from "react-router";
import { API_URL } from "_azkatech/azkHelper";
import { AZKHelper } from "app/modules/components/azkHelper";
import { AzkChatter } from "_azkatech";
import { EmploymentCategory } from "../change-employment-category/form";
import './style.scss'
import { GetSalaryStructureSettingByFilter } from "../salary-structure-setting/_requests";
import { SalaryStructureSetting } from "../salary-structure-setting/model";
import { SCDistribution } from "app/modules/models/SCDistribution";
import { AttendanceHour } from "app/modules/models/AttendanceHour";
import { AuthModel } from "app/modules/auth";
import { checkTrnsactionTypeAccessId } from "app/utilities/transactionTyperequests";
import { useParams } from "react-router-dom";

type Props = {
}
const editTransactionSchema = Yup.object().shape({
    az_country_id: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
        .required('Country is required'),
    brand_id: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
        .required('Brand is required'),
    // job_id: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
    //     .required('Job is required'),
    // grade_id: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
    //     .required('Grade is required'),
    sales: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
        .required('Sales is required').min(1, 'Sales must be grater than Zero!'),
    selling_area: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
        .required('Selling area is required').min(1, 'Selling area must be grater than Zero!')
})

const Transactionform: FC<Props> = () => {
    const { id } = useParams();
    const Swal = require('sweetalert2')
    const intl = useIntl();
    const navigate = useNavigate()
    const formRef = useRef<FormikProps<SalaryStructure>>(null);
    const [brandDomain, setBrandDomain] = useState('');
    const [canAddDetails, setCanAddDetails] = useState<boolean>(false);
    const [locationDomain, setLocationDomain] = useState(`('country_id','=', 0)`);
    const [jobDomain, setJobDomain] = useState(`('country_id','=', 0)`);
    const [currentLocation, setCurrentLocation] = useState<number>(0);
    const [gradeDomain, setGradeDomain] = useState<any[]>([]);
    const [emploumentCatDomain, setEmploumentCatDomain] = useState<any[][]>([]);
    const [personTypeDomain, setPersonTypeDomain] = useState<any[]>([]);
    const [canEdit, setCanEdit] = useState<boolean>(false);
    const [readOnly, setReadOnly] = useState<boolean>(false);
    const [canEditCustom, setCanEditCustom] = useState<boolean>(true);
    const [transactionClosed, setTransactionClosed] = useState<boolean>(false);

    const [canAddTitle, setCanAddTitle] = useState<boolean>(true);
    const [canDuplicate, setCanDuplicate] = useState<boolean>(false);
    const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
    const [employmentCategoryOptions, setEmploymentCategoryOptions] = useState<EmploymentCategory[]>();
    const [salaryStructureSetting, setSalaryStructureSetting] = useState<SalaryStructureSetting[]>();
    const [sCDistribution, setSCDistribution] = useState<SCDistribution>();
    const [needSubmit, setNeedSubmit] = useState<boolean>(false);
    const [canDelete, setCanDelete] = useState<boolean>(false);
    const [canValidate, setCanValidate] = useState<boolean>(false);
    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const [transactionState, setTransactionState] = useState<string>('draft');
    const [transactionStateName, setTransactionStateName] = useState<string>('Draft');
    const [hitSend, setHitSend] = useState<boolean>(false);
    const [objectId, setObjectId] = useState<number>();
    const [loaded, setLoaded] = useState<boolean>(false);


    const pobulateFormData = async (data?: SalaryStructure) => {
        if (data) {
            if (data?.restarted) setCanSubmit(data?.restarted)
            if (data?.state === 'approved' || data?.state === 'rejected') setTransactionClosed(true)
            else setTransactionClosed(false)
            if (data?.can_duplicate) setCanDuplicate(true)
            else {
                if (data?.restarted) setCanAddTitle(data?.restarted)
            }
            if (data?.x_state) setTransactionState(data?.x_state)
            if (data?.state_view) setTransactionStateName(data?.state_view)
            if (data?.current_approval_ids) {
                let _auth = authHelper.getAuth()
                if (!_auth) {
                    const auth = localStorage.getItem('azk-auth-hr-lite')
                    if (auth) {
                        _auth = JSON.parse(auth) as AuthModel
                    }
                }
                if (_auth && (_auth.uid in data.current_approval_ids || data.current_approval_ids.indexOf(_auth.uid) > -1)) {
                    setCanValidate(true)
                }
            }
            handleLocationChange(data.location_id?.id)
            // handleCountryChange(data.az_country_id?.id)
            formRef.current?.setValues({
                id: data.id,
                name: data.name,
                az_country_id: data.az_country_id?.id,
                brand_id: data.brand_id?.id,
                location_id: data.location_id?.id,
                as_effective_date: data.as_effective_date,
                is_service_charge: data.is_service_charge,
                sales: data.sales,
                selling_area: data.selling_area,

                detail_ids: data.detail_ids,
                commission_hc: data.commission_hc,
                non_commission_hc: data.non_commission_hc,
                total_hc: data.total_hc,
                sales_per_sqm: data.sales_per_sqm,
                sqm_employee: data.sqm_employee,
                monthly_working_hours_ft: data.monthly_working_hours_ft,
                monthly_working_hours_pt: data.monthly_working_hours_pt,
                total_monthly_working_hours: data.total_monthly_working_hours,
                revenue_per_hour: data.revenue_per_hour,
                sc_distribution: data.sc_distribution,
                distribution: data.distribution,
                total_points: data.total_points,
                pay_per_point: data.pay_per_point,
                com_hc_salary: data.com_hc_salary,
                non_com_hc_salary: data.non_com_hc_salary,
                total_salary: data.total_salary,
                ratio: data.ratio,
            })
            setCanAddDetails(true)
            if (data && data.detail_ids) {
                await data.detail_ids.forEach((detail, index) => {
                    handleJobChange(detail.job_id, detail, index, data.az_country_id?.id, data.brand_id?.id, data.location_id?.id)
                });
                setLoaded(true)
            } else {
                setLoaded(true)
            }
            calculateTotals()
        } else {
            setLoaded(true)
        }
    }

    const checkPermission = async (action: string) => {
        const allowed = await checkTrnsactionTypeAccessId(MODEL_NAME, action)
        if (!allowed) {
            if (id) {
                const allowed_read = await checkTrnsactionTypeAccessId(MODEL_NAME, 'read')
                if (!allowed_read) {
                    navigate(`/salary-structure/records/`, { state: {}, replace: true });
                    Swal.fire({
                        position: "top-right",
                        title: 'Access Error!',
                        text: 'You does not have access to perform read action!',
                        icon: "error",
                        showConfirmButton: false,
                        timer: AZKHelper.SWAL_TOAST_TIMER,
                        toast: true,
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        timerProgressBar: true
                    })
                } else {
                    setReadOnly(true)
                }
            } else {
                navigate(`/salary-structure/records/`, { state: {}, replace: true });
                Swal.fire({
                    position: "top-right",
                    title: 'Access Error!',
                    text: 'You does not have access to perform this action!',
                    icon: "error",
                    showConfirmButton: false,
                    timer: AZKHelper.SWAL_TOAST_TIMER,
                    toast: true,
                    iconColor: 'white',
                    customClass: {
                        popup: 'colored-toast'
                    },
                    timerProgressBar: true
                })
            }
        }
    }

    useEffect(() => {
        document.body.classList.add('page-loading')
        axios.get(`${API_URL}/hr.employee.category?filters=[]&include_fields=['id','name', 'code']`).then(response => {
            if (response && response.data && response.data.results) {
                setEmploymentCategoryOptions(response.data.results)
            }
        })
        setObjectId(undefined)
        setCanValidate(false)
        setCanEditCustom(true)
        if (id) {
            fecthData(id)
        } else {
            checkPermission('create');
            setCanEdit(true)
            document.body.classList.remove('page-loading')
        }
    }, [])

    const fecthData = (id) => {
        setLoaded(false)
        document.body.classList.add('page-loading')
        setObjectId(parseInt(id))
        checkPermission('write');
        GetSalaryStructureById(parseInt(id)).then((async (data: SalaryStructure) => {
            setObjectId(id)
            pobulateFormData(data)
            const gradeDomains: string[] = []
            await data.detail_ids?.forEach((detail, index) => {
                gradeDomains[index] = `('id','=', ${detail.grade_id})`
            });
            if (data?.can_write && data?.can_write !== null) {
                setCanEdit(data?.can_write)
            } else {
                setCanEdit(false)
            }
            setCanEditCustom(false)
            setLoaded(true)
            // setTransactionClosed(data.validated || data.rejected ? true : false)
            setCanValidate(data?.can_review ? true : false)
            setCanDelete(data?.can_delete ? true : false)
            if (data?.deleted) setReadOnly(true)
            setGradeDomain(gradeDomains)
            document.body.classList.remove('page-loading')
        }))
    }

    const filter = (location_id) => {
        document.body.classList.add('page-loading')
        const country_id = formRef.current?.getFieldProps('az_country_id')?.value
        const brand_id = formRef.current?.getFieldProps('brand_id')?.value
        if (country_id && brand_id && location_id) {
            GetSalaryStructureByFilter(country_id, brand_id, location_id).then((response) => {
                if (response && response.length > 0) {
                    document.body.classList.remove('page-loading')
                    navigate(`/salary-structure/records/${response[0].id}`, { state: { 'transactionId': id }, replace: true });
                    fecthData(response[0].id)
                    setObjectId(response[0].id)
                } else {
                    document.body.classList.remove('page-loading')
                    handleLocationChange(location_id)
                }
            })

            const as_effective_date = formRef.current?.getFieldProps('as_effective_date')?.value
            GetSalaryStructureSettingByFilter(country_id, brand_id, as_effective_date,location_id, undefined).then(async (response) => {
                let titles_available: number[] = []
                if (response && response.length > 0) {
                    await response.forEach(setting => {
                        titles_available.push(setting.job_id.id)
                    })
                    setSalaryStructureSetting(response)
                    setJobDomain(`('id','in', [${titles_available}])`)
                } else {
                    setJobDomain(`('id','in', [])`)
                }
            })
        } else {
            document.body.classList.remove('page-loading')
        }
    }

    const replaceDetailObject = async (detail: SalaryStructureDetail, index: number) => {
        let details: SalaryStructureDetail[] = formRef.current?.getFieldProps('detail_ids').value
        if (!details) details = []
        // replace the detail object
        await details?.map((d, i) => {
            if (i === index) {
                return detail;
            } else {
                return d;
            }
        });
        formRef.current?.setFieldValue('detail_ids', details)
        calculateTotals()
    }

    const deleteDetailObject = async (index: number) => {
        let details: SalaryStructureDetail[] = formRef.current?.getFieldProps('detail_ids').value
        if (!details) details = []
        formRef.current?.setFieldValue('detail_ids', details.filter((d, i) => {
            return i !== index;
        })
        )
    }

    const handleDeleteDetail = (detail: SalaryStructureDetail, index: number) => {
        if (!detail.id) {
            deleteDetailObject(index)
        } else {
            detail.state = 'deleted'
            replaceDetailObject(detail, index)
        }
    }

    const handleAddNewTitle = () => {
        let details: SalaryStructureDetail[] = formRef.current?.getFieldProps('detail_ids').value
        if (!details) details = []
        details.push({
            package: '0',
            head_count: 0,
            commission: 0,
            commission_value: '0',
            total_gross: '0',
            can_edit_job: true
        })
        formRef.current?.setFieldValue('detail_ids', details)
    }

    const handleDuplicate = async () => {
        if (objectId) {
            document.body.classList.add('page-loading')
            document.body.classList.remove('page-loading')
        } else {
            Swal.fire({
                position: "top-right",
                title: 'Error!',
                text: 'This is new salary strucuter can not be duplicate!',
                icon: "error",
                showConfirmButton: false,
                timer: AZKHelper.SWAL_TOAST_TIMER,
                toast: true,
                iconColor: 'white',
                customClass: {
                    popup: 'colored-toast'
                },
                timerProgressBar: true
            })
        }
    }

    const cancel = () => {
        navigate(`/salary-structure/records/`, { state: {}, replace: true });
    }

    const deleteTransaction = () => {
        Swal.fire({
            position: "center",
            title: 'Delete',
            text: 'Delete Transaction confirm?',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
        }).then((result) => {
            document.body.classList.add('page-loading')
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed && id) {
                DeleteSalaryStructure(parseInt(id)).then(() => {
                    cancel()
                    document.body.classList.remove('page-loading')
                    Swal.fire({
                        position: "top-right",
                        title: 'Deleted!',
                        text: 'Your transaction had been deleted!',
                        icon: "warning",
                        showConfirmButton: false,
                        timer: AZKHelper.SWAL_TOAST_TIMER,
                        toast: true,
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        timerProgressBar: true
                    })
                })
            } else if (result.isDenied) {
                Swal.fire('Transaction was not deleted', '', 'info')
            }
        })
    }

    const submitTransaction = (transactionId: number) => {
        setNeedSubmit(false)
        axios.put(`${API_URL}/${MODEL_NAME}/${transactionId}/request_validation`, '{}').then(data => {
            document.body.classList.remove('page-loading')
            navigate(`/salary-structure/records/`, { state: {}, replace: true });
            Swal.fire({
                position: "top-right",
                title: 'Success!',
                text: 'Your transaction had been submited!',
                icon: "success",
                showConfirmButton: false,
                timer: AZKHelper.SUCCESS_SWAL_TOAST_TIMER,
                toast: true,
                iconColor: 'white',
                customClass: {
                    popup: 'colored-toast'
                },
                timerProgressBar: true
            })
        })
    }

    const handleResultOfsubmit = (transactionId: number | undefined, isUpdate: boolean | false) => {
        if (needSubmit && transactionId) {
            submitTransaction(transactionId)
        } else if (isDuplicate) {
            setHitSend(false)
            setCanDuplicate(false)
            formRef.current?.setFieldValue('id', transactionId)
            navigate(`/salary-structure/records/${transactionId}`, { state: {}, replace: true })
            fecthData(transactionId)
        } else {
            document.body.classList.remove('page-loading')
            navigate(`/salary-structure/records/`, { state: {}, replace: true })
            Swal.fire({
                position: "top-right",
                title: 'Success!',
                text: isUpdate ? 'Your transaction had been updated!' : 'Your transaction had been saved!',
                icon: "success",
                showConfirmButton: false,
                timer: AZKHelper.SUCCESS_SWAL_TOAST_TIMER,
                toast: true,
                iconColor: 'white',
                customClass: {
                    popup: 'colored-toast'
                },
                timerProgressBar: true
            })
        }
    }

    const checkElements = async () => {
        let canSave = true
        const elements_list: string[] = []
        let elements: SalaryStructureDetail[] = formRef.current?.getFieldProps('detail_ids').value
        if (!elements) elements = []
        // replace the element object
        await elements.map((element) => {
            const details_key = '' + element.job_id + element.employment_category_id + element.person_type
            if (elements_list.includes(details_key) && element.state !== 'deleted' || !element.package || parseFloat(element.package) == 0)
                canSave = false
            if (element.state !== 'deleted') elements_list.push(details_key)
        });
        return canSave
    }

    const onSubmitHandler = async (values: SalaryStructure, actions: FormikValues) => {
        actions.setSubmitting(true);
        formRef.current?.validateForm()
        if (formRef.current?.isValid) {
            document.body.classList.add('page-loading')
            values['business_type'] = undefined
            checkElements().then(async (canSave) => {
                if (canSave) {
                    if (isNotEmpty(values.id)) {
                        await UpdateSalaryStructure(values).then(data => {
                            if (id) handleResultOfsubmit(parseInt(id), true)
                        })
                    } else {
                        await CreateSalaryStructure(values).then(data => {
                            setLoaded(false)
                            setObjectId(data?.id)
                            setHitSend(true)
                        })
                    }
                } else {
                    document.body.classList.remove('page-loading')
                    Swal.fire({
                        position: "top-right",
                        title: 'Error!',
                        text: 'There is either a duplicate title with timeset or wrong entries',
                        icon: "error",
                        showConfirmButton: false,
                        timer: AZKHelper.SWAL_TOAST_TIMER,
                        toast: true,
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        timerProgressBar: true
                    })
                }
            })
        } else {
            actions.setSubmitting(false)
            formRef.current?.validateForm().then(errors => {
                if (Object.keys(errors).length > 0) {
                    console.log(errors)
                    //   setStatus('error');
                }
            });
        }
    }

    const handleCountryChange = (val) => {
        if (val) {
            document.body.classList.add('page-loading')
            setJobDomain(`('country_id','=', ${val})`);
            setLocationDomain(`('country_id','=', ${val})`);
            const filters = `('az_country_id','=', ${val})`
            axios.get(`${API_URL}/az.attendance.hour?filters=[${filters}]&include_fields=['id','az_country_id','monthly_working_hours_pt', 'monthly_working_hours_ft']`)
                .then((response: AxiosResponse<Response<AttendanceHour[]>>) => response.data)
                .then((response: Response<AttendanceHour[]>) => response['results'])
                .then((response: AttendanceHour[]) => {
                    if (response) {
                        document.body.classList.remove('page-loading')
                        formRef.current?.setFieldValue('monthly_working_hours_ft', response[0].monthly_working_hours_ft)
                        formRef.current?.setFieldValue('monthly_working_hours_pt', response[0].monthly_working_hours_pt)
                        calculateTotals()
                    }
                }, err => {
                    document.body.classList.remove('page-loading')
                })
        } else {
            setJobDomain(`('country_id','=', 0)`);
            setLocationDomain(val ? `('country_id','=', 0)` : '');
        }
    }
    const handleBrandChange = (val) => {
        if (val) {
            document.body.classList.add('page-loading')
            const country_id = formRef.current?.getFieldProps('az_country_id')?.value
            const filters = `('country_id','=', ${country_id}), ('brand_id','=', ${val})`
            setLocationDomain(filters)
            axios.get(`${API_URL}/az.sc.distribution?filters=[${filters}]&include_fields=['id','sc_to_be_distributed', 'sc_shop_percentage']`)
                .then((response: AxiosResponse<Response<SCDistribution[]>>) => response.data)
                .then((response: Response<SCDistribution[]>) => response['results'])
                .then((response: SCDistribution[]) => {
                    document.body.classList.remove('page-loading')
                    if (response) {
                        setSCDistribution(response[0])
                        calculateTotals()
                    }
                }, err => {
                    document.body.classList.remove('page-loading')
                })
        } else {
            setLocationDomain(val ? `('country_id','=', 0)` : '');
        }
    }

    const handleLocationChange = (val) => {
        if (val) {
            document.body.classList.add('page-loading')
            const filters = `('id','=', ${val})`
            axios.get(`${API_URL}/az.branch?filters=[${filters}]&include_fields=['id','name', 'business_type', 'location_id', 'title_ids']`).then(response => {
                if (response && response.data && response.data.results) {
                    setCurrentLocation(response.data.results[0].location_id.id)
                    if (response.data.results[0].title_ids !== null && response.data.results[0].title_ids.length > 0) {
                        setCanAddDetails(true)
                        setJobDomain(`('id','in', [${response.data.results[0].title_ids}])`)
                        if (response.data.results[0].business_type.indexOf('F&B') > -1) {
                            formRef.current?.setFieldValue('business_type', 'F&B')
                            formRef.current?.setFieldValue('is_service_charge', true)
                        } else {
                            formRef.current?.setFieldValue('business_type', 'Fashion')
                            formRef.current?.setFieldValue('is_service_charge', false)
                        }
                        calculateTotals()
                        setLoaded(true)
                        document.body.classList.remove('page-loading')
                    } else {
                        setLoaded(true)
                        setCanAddDetails(false)
                        Swal.fire({
                            position: "top-right",
                            title: 'Sorry!',
                            text: 'Sorry there is no settings matchs the selected country, brand and location!',
                            icon: "warning",
                            showConfirmButton: false,
                            timer: AZKHelper.SWAL_TOAST_TIMER,
                            toast: true,
                            iconColor: 'white',
                            customClass: {
                                popup: 'colored-toast'
                            },
                            timerProgressBar: true
                        })
                        document.body.classList.remove('page-loading')
                    }
                } else {
                    document.body.classList.remove('page-loading')
                }
            }, err => {
                document.body.classList.remove('page-loading')
            })
        }
    }

    const calculateTotals = async () => {
        document.body.classList.add('page-loading')
        const is_service_charge = formRef.current?.getFieldProps('is_service_charge').value
        const sales = parseFloat(formRef.current?.getFieldProps('sales').value)
        const selling_area = parseFloat(formRef.current?.getFieldProps('selling_area').value)
        const details: SalaryStructureDetail[] = formRef.current?.getFieldProps('detail_ids').value
        let commission_hc = 0
        let non_commission_hc = 0
        let monthly_working_hours_ft = formRef.current?.getFieldProps('monthly_working_hours_ft').value
        let monthly_working_hours_pt = formRef.current?.getFieldProps('monthly_working_hours_pt').value
        let total_monthly_working_hours = 0
        let total_monthly_working_hours_for_revenue = 0
        await details?.forEach(detail => {
            if (detail.grade_id && detail.grade_id.id && detail.grade_id.grade_name) {
                detail.grade_id_name = detail.grade_id.grade_name
                detail.grade_id = detail.grade_id.id
            }
            if (detail.state !== 'deleted') {
                if (detail.has_com) {
                    commission_hc += detail.head_count
                    total_monthly_working_hours_for_revenue += detail.head_count * (detail.is_full_time ? monthly_working_hours_ft : monthly_working_hours_pt)
                } else {
                    non_commission_hc += detail.head_count
                }
                total_monthly_working_hours += detail.head_count * (detail.is_full_time ? monthly_working_hours_ft : monthly_working_hours_pt)
            }
        })
        formRef.current?.setFieldValue('total_monthly_working_hours', total_monthly_working_hours)
        const total_hc = commission_hc + non_commission_hc
        formRef.current?.setFieldValue('total_hc', total_hc)
        formRef.current?.setFieldValue('commission_hc', commission_hc)
        formRef.current?.setFieldValue('non_commission_hc', non_commission_hc)

        const sales_per_sqm = selling_area > 0 ? sales / selling_area : 0
        const sqm_employee = commission_hc > 0 ? (selling_area / commission_hc) : 0
        const revenue_per_hour = total_monthly_working_hours_for_revenue > 0 ? (sales / total_monthly_working_hours_for_revenue) : 0
        formRef.current?.setFieldValue('sales_per_sqm', parseFloat(sales_per_sqm.toFixed(2)))
        formRef.current?.setFieldValue('sqm_employee', parseFloat(sqm_employee.toFixed(2)))
        formRef.current?.setFieldValue('revenue_per_hour', parseFloat(revenue_per_hour.toFixed(2)))
        let distribution: number = 0

        let HCxPoints4FT = 0
        let HCxPoints4PT = 0
        let com_hc_salary = 0
        let non_com_hc_salary = 0
        await details?.forEach(detail => {
            if (detail.state !== 'deleted') {
                if (is_service_charge) {
                    if (sCDistribution && sCDistribution.sc_to_be_distributed && sCDistribution.sc_shop_percentage) {
                        distribution = sales * (sCDistribution.sc_to_be_distributed / 100) * (sCDistribution.sc_shop_percentage / 100)
                        if (total_hc) {
                            if (detail.is_full_time)
                                HCxPoints4FT += detail.head_count * detail.commission
                            HCxPoints4FT += detail.head_count * detail.commission
                            if (detail.has_com) detail.commission_value = '' + (sales * sCDistribution.sc_to_be_distributed * detail.commission / (total_hc * 100)).toFixed(2)
                        } else {
                            if (detail.has_com) detail.commission_value = '' + (sales * sCDistribution.sc_to_be_distributed * detail.commission / (total_hc * 100)).toFixed(2)
                        }
                    }
                } else {
                    if (detail.has_com)
                        if (detail.is_full_time) {
                            detail.commission_value = '' + (revenue_per_hour * monthly_working_hours_ft * detail.commission / 100).toFixed(2)
                        }
                        else {
                            detail.commission_value = '' + (revenue_per_hour * monthly_working_hours_pt * detail.commission / 100).toFixed(2)
                        }
                }
                detail.total_gross = (parseFloat(detail.package) + parseFloat(detail.commission_value)).toFixed(2)
                if (detail.has_com) {
                    com_hc_salary += parseFloat(detail.total_gross) * detail.head_count
                }
                else {
                    non_com_hc_salary += parseFloat(detail.total_gross) * detail.head_count
                }
            } else {
                detail.total_gross = (parseFloat(detail.package) + parseFloat(detail.commission_value)).toFixed(2)
            }
        })
        formRef.current?.setFieldValue('com_hc_salary', parseFloat(com_hc_salary.toFixed(2)))
        formRef.current?.setFieldValue('non_com_hc_salary', parseFloat(non_com_hc_salary.toFixed(2)))
        formRef.current?.setFieldValue('total_salary', (com_hc_salary + non_com_hc_salary).toFixed(2))
        formRef.current?.setFieldValue('ratio', (((com_hc_salary + non_com_hc_salary) / sales) * 100).toFixed(2))
        const total_points = (HCxPoints4FT * monthly_working_hours_ft) + (HCxPoints4PT * monthly_working_hours_pt)
        const pay_per_point = distribution / total_points || 0
        formRef.current?.setFieldValue('distribution', distribution.toFixed(2))
        formRef.current?.setFieldValue('total_points', total_points.toFixed(2))
        formRef.current?.setFieldValue('pay_per_point', pay_per_point.toFixed(2))
        document.body.classList.remove('page-loading')
    }

    const pobulateDetailData = async (detailSetting: SalaryStructureSetting, detail: SalaryStructureDetail, index: number) => {
        detail.commission = detailSetting.com || 0
        detail.has_com = detailSetting.has_com
        detail.package = detailSetting.package || '0'
        detail.employment_category_id = detailSetting.employment_category_id?.id
        detail.grade_id = detailSetting.grade_id?.id
        detail.grade_id_name = detailSetting.grade_id?.grade_name
        detail.person_type = detailSetting.person_type
        replaceDetailObject(detail, index)
    }

    const updateSettingValues = (detail: SalaryStructureDetail, index) => {
        const country_id = formRef.current?.getFieldProps('az_country_id')?.value
        const brand_id = formRef.current?.getFieldProps('brand_id')?.value
        const as_effective_date = formRef.current?.getFieldProps('as_effective_date')?.value
        document.body.classList.add('page-loading')
        GetSalaryStructureSettingByFilter(country_id, brand_id, as_effective_date,  currentLocation, detail.job_id, detail.employment_category_id, detail.grade_id, undefined).then((response: SalaryStructureSetting[]) => {
            if (response && response.length > 0) {
                let udpatedetailSetting = response.find((d, i) => {
                    return d.person_type === detail.person_type;
                }) || undefined
                if (udpatedetailSetting) {
                    const detailSetting: SalaryStructureSetting = udpatedetailSetting
                    detail.commission = detailSetting.com || 0
                    detail.has_com = detailSetting.has_com
                    detail.package = detailSetting.package || '0'
                    pobulateDetailData(response[0], detail, index)
                    fillGrades(response, index)
                } else {
                    const detailSetting: SalaryStructureSetting = response[0]
                    detail.commission = detailSetting.com || 0
                    detail.has_com = detailSetting.has_com
                    detail.package = detailSetting.package || '0'
                    pobulateDetailData(response[0], detail, index)
                    // fillGrades(response, index)
                }
            } else {
                GetSalaryStructureSettingByFilter(country_id, brand_id, as_effective_date, undefined, detail.job_id, detail.employment_category_id, detail.grade_id, undefined).then((response: SalaryStructureSetting[]) => {
                    if (response && response.length > 0) {
                        let udpatedetailSetting = response.find((d, i) => {
                            return d.person_type === detail.person_type;
                        }) || undefined
                        if (udpatedetailSetting) {
                            const detailSetting: SalaryStructureSetting = udpatedetailSetting
                            detail.commission = detailSetting.com || 0
                            detail.has_com = detailSetting.has_com
                            detail.package = detailSetting.package || '0'
                            pobulateDetailData(response[0], detail, index)
                            // fillGrades(response, index)
                        } else {
                            const detailSetting: SalaryStructureSetting = response[0]
                            detail.commission = detailSetting.com || 0
                            detail.has_com = detailSetting.has_com
                            detail.package = detailSetting.package || '0'
                            pobulateDetailData(response[0], detail, index)
                            // fillGrades(response, index)
                        }
                    } else {
                        detail.commission = 0
                        detail.package = '0'
                        detail.commission_value = '0'
                        replaceDetailObject(detail, index)
                        Swal.fire({
                            position: "top-right",
                            title: 'Sorry!',
                            text: 'There is no setting matches your entry!',
                            icon: "error",
                            showConfirmButton: false,
                            timer: AZKHelper.SWAL_TOAST_TIMER,
                            toast: true,
                            iconColor: 'white',
                            customClass: {
                                popup: 'colored-toast'
                            },
                            timerProgressBar: true
                        })
                    }
                })
            }
            replaceDetailObject(detail, index)
            document.body.classList.remove('page-loading')
        })
    }

    const handleJobChange = (job_id: number, detail: SalaryStructureDetail, index: number, country_id?: number, brand_id?: number, current_location?: number) => {
        if (job_id && loaded) {
            document.body.classList.add('page-loading')
            if (!country_id) country_id = formRef.current?.getFieldProps('az_country_id')?.value
            if (!brand_id) brand_id = formRef.current?.getFieldProps('brand_id')?.value
            if (!current_location) current_location = currentLocation
            const as_effective_date = formRef.current?.getFieldProps('as_effective_date')?.value
            if (country_id && brand_id)
                GetSalaryStructureSettingByFilter(country_id, brand_id, as_effective_date, currentLocation, job_id).then((response) => {
                    if (response && response.length > 0) {
                        pobulateDetailData(response[0], detail, index)
                        fillGrades(response, index)
                        document.body.classList.remove('page-loading')
                    } else {
                        if (country_id && brand_id)
                            GetSalaryStructureSettingByFilter(country_id, brand_id, as_effective_date, undefined, job_id).then((response) => {
                                if (response && response.length > 0) {
                                    pobulateDetailData(response[0], detail, index)
                                    fillGrades(response, index)
                                }
                            })
                    }
                    document.body.classList.remove('page-loading')
                })
        }
    }

    const fillGrades = async (settings: SalaryStructureSetting[], index: number) => {
        let gradeDomains = gradeDomain
        let personTypeDomains = personTypeDomain
        let emploumentCatDomains = emploumentCatDomain
        const allowedGrades: any[] = []
        const allowedPersonType: any[] = []
        const allowedEmploumentCat: any[] = []
        // allowedGrades.includes()
        await settings.forEach(setting => {
            allowedGrades.push(setting.grade_id.id)
            allowedPersonType.push(setting.person_type)
            allowedEmploumentCat.push(setting.employment_category_id.id)
        });
        gradeDomains[index] = `('id', 'in', [${allowedGrades}])`
        emploumentCatDomains[index] = allowedEmploumentCat
        personTypeDomains[index] = allowedPersonType
        setGradeDomain(gradeDomains)
        setEmploumentCatDomain(emploumentCatDomains)
        setPersonTypeDomain(personTypeDomains)
    }

    return (
        <>
            <div className='salary-structure'>
                <Row className="m-0 my-2">
                    <Col className="mb-3" md='12' lg={id ? '12' : '12'} xl={id ? '12' : '12'}>
                        <Formik
                            innerRef={formRef}
                            validationSchema={editTransactionSchema}
                            onSubmit={onSubmitHandler}
                            initialValues={initialTransaction}
                            validateeOnChange={true}
                            validateOnMount={true}
                            initialErrors={''}
                        >
                            {({ values, setFieldValue, handleSubmit, isSubmitting }) => {
                                // ,validateForm,isSubmitting, errors, touched, isValidating
                                return (
                                    <>
                                        {/* {id && (<AzkStatusBar transactionId={id} modelName={MODEL_NAME}
                                        current={transactionState}/>
                                    )} */}
                                        {canValidate && id &&
                                            <AzkActionButtons
                                                actionUrl={`${API_URL}/${MODEL_NAME}/${parseInt(id)}/`}
                                                thenNavigate="/salary-structure/records/"
                                                divStyle="card py-2 mb-3"
                                                buttons={[
                                                    { title: "Approve", alertSuccess: intl.formatMessage({ id: 'TRANSACTION.APPROVED' }), method: "validate_tier", confirmMsg: "Approve Transaction, Confirm?", cssClass: "btn btn-light btn-light-success", icon: "fa-thumbs-up" },
                                                    { title: "Review", alertSuccess: intl.formatMessage({ id: 'TRANSACTION.REVIEW' }), method: "restart_validation", confirmMsg: "Send Transaction back to draft, Confirm?", cssClass: "btn btn-light btn-light-warning", icon: "fa-refresh" },
                                                    { title: "Decline", alertSuccess: intl.formatMessage({ id: 'TRANSACTION.DECLINE' }), method: "reject_tier", confirmMsg: "Decline Transaction, Confirm?", cssClass: "btn btn-light btn-light-danger", icon: "fa-times" },
                                                ]} />}
                                        <Form onSubmit={handleSubmit} noValidate className="card">
                                            <fieldset disabled={!canEdit || readOnly}>
                                                <Row className="m-0 my-2 mb-3">
                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                        <Form.Group className='mb-2'>
                                                            <Form.Label className='required'>
                                                                Country
                                                            </Form.Label>
                                                            <AzkSelect
                                                                compID="az_country_select"
                                                                modelName="az.country"
                                                                valueField="id"
                                                                titleField="name"
                                                                dataDomain=''
                                                                isMultiple={false}
                                                                allowEmpty={true}
                                                                fieldName='az_country_id'
                                                                disabled={!canEdit || !canEditCustom}
                                                                onChange={(value) => {
                                                                    setFieldValue('az_country_id', parseInt(value))
                                                                    handleCountryChange(value)
                                                                }}
                                                                defaultValue={values.az_country_id} />
                                                            <div className='text-danger'>
                                                                <ErrorMessage name='az_country_id' />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                        <Form.Group className='mb-2'>
                                                            <Form.Label className='required'>
                                                                Brand
                                                            </Form.Label>
                                                            <AzkSelect
                                                                compID="az_brand_select"
                                                                modelName="az.brand"
                                                                valueField="id"
                                                                titleField="name"
                                                                dataDomain={brandDomain}
                                                                isMultiple={false}
                                                                allowEmpty={true}
                                                                fieldName='brand_id'
                                                                disabled={!canEdit || !canEditCustom}
                                                                onChange={(value) => {
                                                                    setFieldValue('brand_id', parseInt(value))
                                                                    handleBrandChange(value)
                                                                }}
                                                                defaultValue={values.brand_id} />
                                                            <div className='text-danger'>
                                                                <ErrorMessage name='brand_id' />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                        <Form.Group className='mb-2'>
                                                            <Form.Label className='required'>
                                                                Location
                                                            </Form.Label>
                                                            <AzkSelect
                                                                compID="az_location_select"
                                                                modelName="az.branch"
                                                                valueField="id"
                                                                titleField="name"
                                                                dataDomain={locationDomain}
                                                                isMultiple={false}
                                                                allowEmpty={true}
                                                                fieldName='location_id'
                                                                disabled={!canEdit || !canEditCustom}
                                                                onChange={(value) => {
                                                                    setFieldValue('location_id', parseInt(value))
                                                                    filter(parseInt(value))
                                                                }}
                                                                defaultValue={values.location_id} />
                                                            <div className='text-danger'>
                                                                <ErrorMessage name='location_id' />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                        <Form.Group className='mb-2'>
                                                            <Form.Label className='required'>
                                                                Effective date
                                                            </Form.Label>
                                                            <Form.Control
                                                                className='p-1'
                                                                type='date'
                                                                onChange={(value) => {
                                                                    setFieldValue('as_effective_date', value.target.value)
                                                                }}
                                                                disabled={!canEdit}
                                                                value={moment(values.as_effective_date).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                                            />
                                                            <div className='text-danger'>
                                                                <ErrorMessage name='as_effective_date' />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    {/* <Col lg="8" md="8" sm="6" xs="12" className="d-flex align-items-end justify-content-center">
                                                        <button
                                                            type='reset'
                                                            onClick={() => filter()}
                                                            className='btn btn-primary me-3 mx-5'>
                                                            Filter
                                                        </button>
                                                    </Col> */}
                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                        <Form.Group className='mb-2'>
                                                            <Form.Label className='required'>
                                                                Sales
                                                            </Form.Label>
                                                            <Form.Control
                                                                className='p-1'
                                                                id='sales'
                                                                name='sales'
                                                                disabled={!canEdit}
                                                                onChange={(value) => {
                                                                    setFieldValue('sales', value.target.value)
                                                                    calculateTotals()
                                                                }
                                                                }
                                                                defaultValue={values.sales}
                                                                type='number'
                                                                min={0}
                                                            />
                                                            <div className='text-danger'>
                                                                <ErrorMessage name='sales' />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                        <Form.Group className='mb-2'>
                                                            <Form.Label className='required'>
                                                                Selling area
                                                            </Form.Label>
                                                            <Form.Control
                                                                className='p-1'
                                                                id='selling_area'
                                                                name='selling_area'
                                                                disabled={!canEdit}
                                                                onChange={(value) => {
                                                                    setFieldValue('selling_area', value.target.value)
                                                                    calculateTotals()
                                                                }
                                                                }
                                                                defaultValue={values.selling_area}
                                                                type='number'
                                                                min={0}
                                                            />
                                                            <div className='text-danger'>
                                                                <ErrorMessage name='selling_area' />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row className="m-0 my-2">
                                                    <h4 className="heading-1"><span>Commision Head Count</span></h4>
                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                        <Form.Group className='mb-2'>
                                                            <Form.Label className='required'>
                                                                Commission HC
                                                            </Form.Label>
                                                            <Form.Control
                                                                className='p-1'
                                                                id='commission_hc'
                                                                name='commission_hc'
                                                                disabled={true}
                                                                value={values.commission_hc}
                                                                type='number'
                                                                min={0}
                                                            />
                                                            <div className='text-danger'>
                                                                <ErrorMessage name='commission_hc' />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                        <Form.Group className='mb-2'>
                                                            <Form.Label className='required'>
                                                                Non commission HC
                                                            </Form.Label>
                                                            <Form.Control
                                                                className='p-1'
                                                                id='non_commission_hc'
                                                                name='non_commission_hc'
                                                                disabled={true}
                                                                value={values.non_commission_hc}
                                                                type='number'
                                                                min={0}
                                                            />
                                                            <div className='text-danger'>
                                                                <ErrorMessage name='non_commission_hc' />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                        <Form.Group className='mb-2'>
                                                            <Form.Label className='required'>
                                                                Total HC
                                                            </Form.Label>
                                                            <Form.Control
                                                                className='p-1'
                                                                id='total_hc'
                                                                name='total_hc'
                                                                disabled={true}
                                                                value={values.total_hc}
                                                                type='number'
                                                                min={0}
                                                            />
                                                            <div className='text-danger'>
                                                                <ErrorMessage name='total_hc' />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                        <Form.Group className='mb-2'>
                                                            <Form.Label className='required'>
                                                                Sales per SQM
                                                            </Form.Label>
                                                            <Form.Control
                                                                className='p-1'
                                                                id='sales_per_sqm'
                                                                name='sales_per_sqm'
                                                                disabled={true}
                                                                value={parseFloat(values.sales_per_sqm).toFixed(2)}
                                                                type='number'
                                                                min={0}
                                                            />
                                                            <div className='text-danger'>
                                                                <ErrorMessage name='sales_per_sqm' />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                        <Form.Group className='mb-2'>
                                                            <Form.Label className='required'>
                                                                SQM employee
                                                            </Form.Label>
                                                            <Form.Control
                                                                className='p-1'
                                                                id='sqm_employee'
                                                                name='sqm_employee'
                                                                disabled={true}
                                                                value={parseFloat(values.sqm_employee).toFixed(2)}
                                                                type='number'
                                                                min={0}
                                                            />
                                                            <div className='text-danger'>
                                                                <ErrorMessage name='sqm_employee' />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    {values.is_service_charge ? (
                                                        <>
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2'>
                                                                    <Form.Label className='required'>
                                                                        Distribution
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        className='p-1'
                                                                        id='distribution'
                                                                        name='distribution'
                                                                        disabled={true}
                                                                        value={parseFloat(values.distribution).toFixed(2)}
                                                                        type='number'
                                                                        min={0}
                                                                    />
                                                                    <div className='text-danger'>
                                                                        <ErrorMessage name='distribution' />
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2'>
                                                                    <Form.Label className='required'>
                                                                        Total points
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        className='p-1'
                                                                        id='total_points'
                                                                        name='total_points'
                                                                        disabled={true}
                                                                        value={parseFloat(values.total_points).toFixed(2)}
                                                                        type='number'
                                                                        min={0}
                                                                    />
                                                                    <div className='text-danger'>
                                                                        <ErrorMessage name='total_points' />
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2'>
                                                                    <Form.Label className='required'>
                                                                        Pay per point
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        className='p-1'
                                                                        id='pay_per_point'
                                                                        name='pay_per_point'
                                                                        disabled={true}
                                                                        value={parseFloat(values.pay_per_point).toFixed(2)}
                                                                        type='number'
                                                                        min={0}
                                                                    />
                                                                    <div className='text-danger'>
                                                                        <ErrorMessage name='pay_per_point' />
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                        </>
                                                    ) : ('')}
                                                </Row>
                                                <Row className="m-0 my-2">
                                                    <h4 className="heading-1"><span>Monthly Working hours</span></h4>

                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                        <Form.Group className='mb-2'>
                                                            <Form.Label className='required'>
                                                                Monthly working hours FT
                                                            </Form.Label>
                                                            <Form.Control
                                                                className='p-1'
                                                                id='monthly_working_hours_ft'
                                                                name='monthly_working_hours_ft'
                                                                disabled={true}
                                                                value={values.monthly_working_hours_ft}
                                                                type='number'
                                                                min={0}
                                                            />
                                                            <div className='text-danger'>
                                                                <ErrorMessage name='monthly_working_hours_ft' />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                        <Form.Group className='mb-2'>
                                                            <Form.Label className='required'>
                                                                Monthly working hours PT
                                                            </Form.Label>
                                                            <Form.Control
                                                                className='p-1'
                                                                id='monthly_working_hours_pt'
                                                                name='monthly_working_hours_pt'
                                                                disabled={true}
                                                                value={values.monthly_working_hours_pt}
                                                                type='number'
                                                                min={0}
                                                            />
                                                            <div className='text-danger'>
                                                                <ErrorMessage name='monthly_working_hours_pt' />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                        <Form.Group className='mb-2'>
                                                            <Form.Label className='required'>
                                                                Total monthly working hours
                                                            </Form.Label>
                                                            <Form.Control
                                                                className='p-1'
                                                                id='total_monthly_working_hours'
                                                                name='total_monthly_working_hours'
                                                                disabled={true}
                                                                value={values.total_monthly_working_hours}
                                                                type='number'
                                                                min={0}
                                                            />
                                                            <div className='text-danger'>
                                                                <ErrorMessage name='total_monthly_working_hours' />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                        <Form.Group className='mb-2'>
                                                            <Form.Label className='required'>
                                                                Revenue per hour
                                                            </Form.Label>
                                                            <Form.Control
                                                                className='p-1'
                                                                id='revenue_per_hour'
                                                                name='revenue_per_hour'
                                                                disabled={true}
                                                                value={parseFloat(values.revenue_per_hour).toFixed(2)}
                                                                type='number'
                                                                min={0}
                                                            />
                                                            <div className='text-danger'>
                                                                <ErrorMessage name='revenue_per_hour' />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row className="m-0 my-2">
                                                    <h4 className="heading-1"><span>Total Salay</span></h4>

                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                        <Form.Group className='mb-2'>
                                                            <Form.Label className='required'>
                                                                Com HC salary
                                                            </Form.Label>
                                                            <Form.Control
                                                                className='p-1'
                                                                id='com_hc_salary'
                                                                name='com_hc_salary'
                                                                disabled={true}
                                                                value={parseFloat(values.com_hc_salary).toFixed(2)}
                                                                type='number'
                                                                min={0}
                                                            />
                                                            <div className='text-danger'>
                                                                <ErrorMessage name='com_hc_salary' />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                        <Form.Group className='mb-2'>
                                                            <Form.Label className='required'>
                                                                NON Com HC salary
                                                            </Form.Label>
                                                            <Form.Control
                                                                className='p-1'
                                                                id='non_com_hc_salary'
                                                                name='non_com_hc_salary'
                                                                disabled={true}
                                                                value={parseFloat(values.non_com_hc_salary).toFixed(2)}
                                                                type='number'
                                                                min={0}
                                                            />
                                                            <div className='text-danger'>
                                                                <ErrorMessage name='non_com_hc_salary' />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                        <Form.Group className='mb-2'>
                                                            <Form.Label className='required'>
                                                                Total salary
                                                            </Form.Label>
                                                            <Form.Control
                                                                className='p-1'
                                                                id='total_salary'
                                                                name='total_salary'
                                                                disabled={true}
                                                                value={parseFloat(values.total_salary).toFixed(2)}
                                                                type='number'
                                                                min={0}
                                                            />
                                                            <div className='text-danger'>
                                                                <ErrorMessage name='total_salary' />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                        <Form.Group className='mb-2'>
                                                            <Form.Label className='required'>
                                                                Ratio %
                                                            </Form.Label>
                                                            <Form.Control
                                                                className='p-1'
                                                                id='ratio'
                                                                name='ratio'
                                                                disabled={true}
                                                                value={parseFloat(values.ratio).toFixed(2)}
                                                                type='number'
                                                                min={0}
                                                            />
                                                            <div className='text-danger'>
                                                                <ErrorMessage name='ratio' />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </fieldset>
                                            {canAddDetails &&
                                                <Row className="m-0 my-2">
                                                    <h4 className="heading-1"><span>Details</span></h4>
                                                    <Col lg="12" md="12" sm="12" xs="12" className="d-flex justify-content-between">
                                                        {canAddTitle && !transactionClosed && !readOnly? (
                                                            <>
                                                                <button
                                                                    type="reset"
                                                                    disabled={!canEdit}
                                                                    onClick={() => { handleAddNewTitle() }}
                                                                    className='btn btn-info p-1 px-2 ml-1'
                                                                ><i className="fa fa-plus p-0"></i> Add Title</button>
                                                            </>
                                                        ) : (<></>)}
                                                        {canDuplicate && objectId && transactionClosed ? (
                                                            <>
                                                                <button
                                                                    type="submit"
                                                                    onClick={() => {
                                                                        values.id = undefined
                                                                        values.as_effective_date = moment().format(AZKHelper.FRONTEND_DATE_FORMAT)
                                                                        setIsDuplicate(true)
                                                                    }}
                                                                    className='btn btn-info p-1 px-2 ml-1'
                                                                ><i className="fa fa-refresh p-0"></i> Duplicate</button>
                                                            </>
                                                        ) : (<></>)}
                                                    </Col>
                                                    <fieldset disabled={!canEdit || readOnly || transactionClosed}>
                                                        <Col lg="12" md="12" sm="12" xs="12">
                                                            <div className=''> {/* table-responsive */}
                                                                <table
                                                                    id='kt_table_users'
                                                                    className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                                                                    <thead>
                                                                        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                                                            <th>Title</th>
                                                                            <th>FT/PT</th>
                                                                            <th>HC</th>
                                                                            <th>Type</th>
                                                                            <th>Grade</th>
                                                                            <th>
                                                                                {values.is_service_charge ? (
                                                                                    <>Has SC</>) : (<>Has COM</>)}
                                                                            </th>
                                                                            <th>package</th>
                                                                            <th>{values.is_service_charge ? (
                                                                                <>Points</>) : (<>COM %</>)}</th>
                                                                            <th>{values.is_service_charge ? (
                                                                                <>Points Value</>) : (<>COM Value</>)}</th>
                                                                            <th>Total Gross</th>
                                                                            <th>state</th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className='text-gray-600 fw-bold'>
                                                                        {(values && values.detail_ids && values.detail_ids.length > 0) ? (values.detail_ids.map((detail, index) => {
                                                                            return (
                                                                                <>
                                                                                    <tr>
                                                                                        <td className="py-0">
                                                                                            <Form.Group className='mb-2 fs-8'>
                                                                                            <OverlayTrigger
                    placement='top'
                    overlay={
                        <Tooltip id='tooltip-user-name'>khaled king</Tooltip>} children={
                                         <AzkSelect
                                                                                                    compID={`az_job_select-` + index}
                                                                                                    modelName="hr.job"
                                                                                                    valueField="id"
                                                                                                    titleField="name"
                                                                                                    dataDomain={jobDomain}
                                                                                                    isMultiple={false}
                                                                                                    allowEmpty={true}
                                                                                                    fieldName={`job_id-` + index}
                                                                                                    disabled={!canEdit || detail.state === 'deleted' || !detail.can_edit_job || transactionClosed}
                                                                                                    onChange={(value) => {
                                                                                                        detail.job_id = parseInt(value)
                                                                                                        handleJobChange(value, detail, index, undefined, undefined, undefined)
                                                                                                    }}
                                                                                                    defaultValue={detail.job_id} />

                        }></OverlayTrigger>
                                                                                                <div className='text-danger'>
                                                                                                    <ErrorMessage name='job_id' />
                                                                                                </div>
                                                                                            </Form.Group>
                                                                                        </td>
                                                                                        <td className="py-0">
                                                                                            {detail.job_id ? (<>
                                                                                                <div>
                                                                                                    {employmentCategoryOptions?.map((option, index1) => (

                                                                                                        <Form.Check className="py-1 fs-8"
                                                                                                            label={option.code}
                                                                                                            value={option.id}
                                                                                                            checked={detail.job_id ? detail.employment_category_id ? detail.employment_category_id === option.id : true : false}
                                                                                                            name={`employment-category-option-` + index + '-' + index1 + '-' + option.id}
                                                                                                            onChange={(value) => {
                                                                                                                if (detail.employment_category_id !== value && detail.id)
                                                                                                                    detail.state = 'changed'
                                                                                                                detail.employment_category_id = option.id
                                                                                                                // replaceDetailObject(detail, index)
                                                                                                                updateSettingValues(detail, index)
                                                                                                            }}
                                                                                                            disabled={(emploumentCatDomain && emploumentCatDomain[index] && !emploumentCatDomain[index].includes(option.id)) || detail.state === 'deleted' || !detail.can_edit_job}
                                                                                                            type="radio"
                                                                                                            id={`employment-category-option-` + index + '-' + index1 + '-' + option.id}
                                                                                                        />
                                                                                                    )
                                                                                                    )}
                                                                                                </div>
                                                                                            </>) : (<></>)}
                                                                                        </td>
                                                                                        <td className="py-0">
                                                                                            {detail.employment_category_id ? (<>
                                                                                                <Form.Control
                                                                                                    className='p-1'
                                                                                                    id='head_count'
                                                                                                    name='head_count'
                                                                                                    disabled={!canEdit || detail.state === 'deleted'}
                                                                                                    onChange={(event) => {
                                                                                                        if (event.target.value) {
                                                                                                            if (detail.head_count !== parseInt(event.target.value) && detail.id)
                                                                                                                detail.state = 'changed'
                                                                                                            detail.head_count = parseInt(event.target.value)
                                                                                                            replaceDetailObject(detail, index)
                                                                                                        }
                                                                                                    }}
                                                                                                    value={detail.head_count}
                                                                                                    type='number'
                                                                                                />
                                                                                            </>) : (<></>)}
                                                                                        </td>
                                                                                        <td className="py-0">
                                                                                            <div className="">
                                                                                                <Form.Check className="py-1 fs-8"
                                                                                                    label='National'
                                                                                                    value='national'
                                                                                                    checked={detail.person_type ? 'national' === detail.person_type : false}
                                                                                                    name={"person-type-option-" + index}
                                                                                                    onChange={(value) => {
                                                                                                        detail.person_type = value.target.value
                                                                                                        // replaceDetailObject(detail, index)
                                                                                                        updateSettingValues(detail, index)
                                                                                                    }}
                                                                                                    disabled={(personTypeDomain && personTypeDomain[index] && !personTypeDomain[index].includes('national')) || detail.state === 'deleted' || !detail.can_edit_job}

                                                                                                    type="radio"
                                                                                                    id={`person-type-option-national`}
                                                                                                />
                                                                                                <Form.Check className="py-1 fs-8"
                                                                                                    label='Expat'
                                                                                                    value='expat'
                                                                                                    checked={detail.person_type ? 'expat' === detail.person_type : false}
                                                                                                    disabled={(personTypeDomain && personTypeDomain[index] && !personTypeDomain[index].includes('expat')) || detail.state === 'deleted' || !detail.can_edit_job}
                                                                                                    name={"person-type-option-" + index}
                                                                                                    onChange={(value) => {
                                                                                                        detail.person_type = value.target.value
                                                                                                        // replaceDetailObject(detail, index)
                                                                                                        updateSettingValues(detail, index)
                                                                                                    }}
                                                                                                    type="radio"
                                                                                                    id={`person-type-option-expat`}
                                                                                                />
                                                                                                <Form.Check className="py-1 fs-8"
                                                                                                    label='N/A'
                                                                                                    value='N/A'
                                                                                                    checked={detail.person_type ? 'N/A' === detail.person_type : true}
                                                                                                    disabled={(personTypeDomain && personTypeDomain[index] && !personTypeDomain[index].includes('N/A')) || detail.state === 'deleted' || !detail.can_edit_job}
                                                                                                    name={"person-type-option-" + index}
                                                                                                    onChange={(value) => {
                                                                                                        detail.person_type = value.target.value
                                                                                                        // replaceDetailObject(detail, index)
                                                                                                        // if (detail.person_type !== value)
                                                                                                        updateSettingValues(detail, index)
                                                                                                    }}
                                                                                                    type="radio"
                                                                                                    id={`person-type-option-N/A`}
                                                                                                />
                                                                                            </div>
                                                                                        </td>
                                                                                        <td className="py-0 fs-8">
                                                                                            <Form.Control
                                                                                                className='p-1'
                                                                                                id={`grade_id-` + index}
                                                                                                name={`grade_id-` + index}
                                                                                                disabled={true}
                                                                                                value={detail.grade_id_name}
                                                                                                type="text"
                                                                                            />
                                                                                        </td>
                                                                                        <td className="py-0">
                                                                                            <Form.Check className="py-1 fs-8"
                                                                                                id='has_com'
                                                                                                type="checkbox"
                                                                                                name='has_com'
                                                                                                disabled={true}
                                                                                                // onChange={(value) =>
                                                                                                //     detail.has_com = value ? true : false
                                                                                                // }
                                                                                                checked={detail.has_com}
                                                                                            /></td>
                                                                                        <td className="py-0">
                                                                                            <Form.Control
                                                                                                className='p-1'
                                                                                                id={`package-` + index}
                                                                                                name={`package-` + index}
                                                                                                disabled={true}
                                                                                                value={detail.package}
                                                                                                type='number'
                                                                                            /></td>
                                                                                        <td className="py-0">
                                                                                            <Form.Control
                                                                                                className='p-1'
                                                                                                id={`com-` + index}
                                                                                                name={`com-` + index}
                                                                                                disabled={true}
                                                                                                value={detail.commission}
                                                                                                type='number'
                                                                                            /></td>
                                                                                        <td className="py-0">
                                                                                            <Form.Control
                                                                                                className='p-1'
                                                                                                id={`commission_value-` + index}
                                                                                                name={`commission_value-` + index}
                                                                                                disabled={true}
                                                                                                value={parseFloat(detail.commission_value).toFixed(2)}
                                                                                                type='number'
                                                                                            /></td>
                                                                                        <td className="py-0">
                                                                                            <Form.Control
                                                                                                className='p-1'
                                                                                                id={`total_gross-` + index}
                                                                                                name={`total_gross-` + index}
                                                                                                disabled={true}
                                                                                                value={parseFloat(detail.total_gross).toFixed(2)}
                                                                                                type='number'
                                                                                            /></td>
                                                                                        <td className="py-0">
                                                                                            <Form.Label
                                                                                                className={`p-1 ${detail.state === 'new' ? "text-primary" : ''} ${detail.state === "approved" ? "text-success" : ''} ${detail.state === "deleted" ? "text-danger" : ''} ${detail.state === "changed" ? "text-info" : ''}`}
                                                                                                id={`state-` + index}
                                                                                                name={`state-` + index}
                                                                                            >{detail.state === 'new' ? "New" : detail.state === "approved" ? "Approved" : detail.state === "deleted" ? "Deleted" : detail.state === "changed" ? "Changed" : 'New'}</Form.Label></td>
                                                                                        <td className="py-2">
                                                                                            <button
                                                                                                type="reset"
                                                                                                onClick={() => { handleDeleteDetail(detail, index) }}
                                                                                                className='btn btn-sm btn-icon btn-active-light-primary me-1'
                                                                                            ><i className="bi bi-trash p-0"></i></button>
                                                                                        </td>
                                                                                    </tr>
                                                                                </>
                                                                            )
                                                                        })) : (
                                                                            <tr>
                                                                                <td colSpan={11}>
                                                                                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                                                                        No matching records found
                                                                                    </div>
                                                                                </td>
                                                                            </tr>)}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className='text-danger'>
                                                                <ErrorMessage name='element' />
                                                            </div>
                                                        </Col>
                                                    </fieldset>
                                                </Row>
                                            }

                                            {/* begin::Actions */}
                                            <div className='py-5 d-flex justify-content-end'>
                                                {!canValidate && canEdit && !transactionClosed &&
                                                    <button
                                                        title="Submit for approval"
                                                        type='submit'
                                                        onClick={() => { setNeedSubmit(true); setIsDuplicate(false) }}
                                                        className='btn btn-light btn-light-info mx-5 btn-sm'
                                                        disabled={!canEdit}>
                                                        <i className="fa fa-podcast fs-2" aria-hidden="true"></i>
                                                        <span className='indicator-label'>Submit</span>
                                                    </button>
                                                }
                                                {!canValidate && canEdit && !transactionClosed &&
                                                    <button
                                                        title="Save"
                                                        type='submit'
                                                        onClick={() => { setNeedSubmit(false); setIsDuplicate(false) }}
                                                        className='btn btn-light btn-light-primary mx-5 btn-sm  '
                                                        disabled={isSubmitting || !canEdit}
                                                    >
                                                        {/* <KTIcon iconName='fa-save' className='fs-2' /> */}
                                                        <i className="fas fa-check fs-2"></i>
                                                        <span className='indicator-label'>Save</span>
                                                    </button>
                                                }
                                                {!canValidate &&
                                                    <button
                                                        title="Discard & back"
                                                        type='reset'
                                                        onClick={() => cancel()}
                                                        className='btn btn-light btn-active-light-primary me-3 mx-5 btn-sm'
                                                        data-kt-users-modal-action='cancel'
                                                    >
                                                        <KTIcon iconName='exit-left' className='fs-2' />
                                                        <span className='indicator-label'>Discard</span>

                                                    </button>
                                                }
                                                {canDelete && id && !transactionClosed &&
                                                    <button
                                                        title="Delete"
                                                        type='reset'
                                                        onClick={() => deleteTransaction()}
                                                        className='btn btn-light btn-light-danger me-3 mx-5 btn-sm'
                                                        data-kt-users-modal-action='delete'
                                                    >
                                                        <KTIcon iconName='trash' className='fs-2' />
                                                        <span className='indicator-label'>Delete</span>

                                                    </button>
                                                }
                                            </div>
                                            {/* end::Actions */}
                                        </Form>
                                    </>
                                )
                            }}
                        </Formik>
                    </Col>
                    <Col md='12' lg='12' xl='12'>
                        {id && loaded && (<AzkStatusRibbon transactionId={parseInt(id)} modelName={MODEL_NAME}
                            current={transactionState} currentName={transactionStateName} />
                        )}
                        <AzkChatter transactionClosed={transactionClosed} isDrawer={true} modelName={MODEL_NAME} itemID={objectId} hitSend={hitSend} sendMessagesCallback={handleResultOfsubmit} />
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default Transactionform;