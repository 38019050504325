import axios, { ResponseType } from "axios";
import { SelfService} from "./model";
import { FC, useEffect, useRef, useState } from "react";
import {FormikProps } from 'formik'
import { GetSelfServiceById, MODEL_NAME } from "./_requests";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL } from "_azkatech/azkHelper";

type Props = {
    serviceId: any,
    closeModal: () => {}
}

const TransactionAPPViewform: FC<Props> = ({ serviceId, closeModal }) => {
    const { id } = useParams();
    const navigate = useNavigate()
    const formRef = useRef<FormikProps<SelfService>>(null);

    const [transaction, setTransaction] = useState<SelfService>();
    const [downloadCliked, setDownloadCliked] = useState<boolean>(false);

    useEffect(() => {
        document.body.classList.add('page-loading')
        formRef.current?.resetForm()
        if (serviceId) {
            document.body.classList.add('page-loading')
            GetSelfServiceById(parseInt(serviceId)).then(((data: SelfService) => {
                setTransaction(data)
                document.body.classList.remove('page-loading')
            }))
        } else {
            document.body.classList.remove('page-loading')
        }
    }, [id])


    const cancel = () => {
        navigate(`/self-service/service/`, { state: {}, replace: true });
    }

    const downloadFilePDF = (file_id, responseType: ResponseType = 'blob') => {
        document.body.classList.add('page-loading')
        if (file_id) {
            axios
                .get(`${API_URL}/attachments/${file_id.id}`, {
                    responseType,
                })
                .then((response) => {
                    let file_name = response.headers['content-disposition']
                    file_name = file_name.replace('attachment; filename=', '')
                    file_name = 'Letter ' + file_name.replaceAll('\"', '')
                    const blob = new Blob([response.data], { type: response.headers['content-type']})
                    // const url = window.URL.createObjectURL(blob)
                    // const link = document.createElement('a')
                    // link.href = url
                    // link.setAttribute('download', file_name)
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = function () {
                        const base64Data = reader.result;
                        // Create and trigger the download
                        // Post the message with Base64 content
                        const link = document.createElement('a');
                        if (base64Data){
                        let base64DataStr = base64Data?.toString();
                        base64DataStr = base64DataStr.replace(';base64', `;filename=${file_name};base64`)
                        link.href = base64DataStr;
                        link.setAttribute('download', file_name);
                        link.download = `${file_name}`;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        }
                    };
                    // document.body.appendChild(link)
                    // link.click()
                    // document.body.removeChild(link)
                    // window.URL.revokeObjectURL(url)
                    document.body.classList.remove('page-loading')
                })
                .catch((error) => {
                    console.error('Download error:', error)
                    document.body.classList.remove('page-loading')
                })
        } else {
            document.body.classList.remove('page-loading')
        }
    }
    return (
        <>{transaction && <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
                style={{ zIndex: 99999 }}
            >
                {/* begin::Modal dialog */}
                <div className='modal-dialog modal-dialog-centered px-3'>
                    {/* begin::Modal content */}
                    <div className='modal-content'>

                        <div className='modal-header py-3 px-2'>
                            {/* begin::Modal title */}
                            <h4 className='m-0'>Details</h4>
                            {/* end::Modal title */}

                            {/* begin::Close */}
                            {/* <div
                                className='btn btn-icon btn-sm btn-active-icon-primary'
                                data-kt-users-modal-action='close'
                                onClick={() => closeModal()}
                                style={{ cursor: 'pointer' }}
                            >
                                <i className="fa fa-times fs-1 btn btn-sm" aria-hidden="true"></i>
                            </div> */}
                            {/* end::Close */}
                        </div>
                        {/* <div className='text-center py-3'>
                            <h4 className='m-0'>Leave Details</h4>
                        </div> */}
                        {/* begin::Modal body */}
                        <div className='' >
                            <Row className="m-0 py-3">
                                <Col lg="4" md="4" sm="6" xs="12">
                                    <Form.Group className='mb-2 d-flex justify-content-between'>
                                        <Form.Label className="payslip-form-label">
                                            Id/Name
                                        </Form.Label>
                                        <Form.Label className="payslip-form-label-value">
                                            {transaction?.azadea_employee_name}
                                        </Form.Label>
                                    </Form.Group>
                                </Col>

                                {transaction && transaction.self_service_field && transaction.self_service_field.length > 0 &&
                                    transaction.self_service_field.map(field => {
                                        return <>
                                            <Col lg="4" md="4" sm="6" xs="12">
                                                <Form.Group className='mb-2 d-flex justify-content-between'>
                                                    <Form.Label className="payslip-form-label">
                                                        {field.key}
                                                    </Form.Label>
                                                    <Form.Label className="payslip-form-label-value">
                                                        {field.value}
                                                    </Form.Label>
                                                </Form.Group>
                                            </Col>
                                        </>
                                    })
                                }
                                {transaction?.comment && transaction.validated &&
                                    <Col lg="4" md="4" sm="6" xs="12">
                                        <Form.Group className='mb-2 d-flex justify-content-between'>
                                            <Form.Label className="payslip-form-label">
                                                Approver Comment
                                            </Form.Label>
                                            <Form.Label className="payslip-form-label-value">
                                                {transaction?.comment}
                                            </Form.Label>
                                        </Form.Group>
                                    </Col>
                                }
                            </Row>
                        </div>
                        {/* end::Modal body */}
                        <div className='my-2 row m-0'>
                            <div className={`${transaction.file_id && transaction.validated? 'col-12 d-flex justify-content-between py-3':'col-12 d-flex justify-content-end py-3'}`}>
                                {transaction?.file_id && transaction.validated &&
                                    <button id='download-button' type='button' className='btn btn-dark rounded-0 btn-sm col-4'
                                        onClick={() => {
                                            downloadFilePDF(transaction?.file_id);
                                            setDownloadCliked(true);
                                            setTimeout(() => {
                                                setDownloadCliked(false);
                                            }, 5000);
                                        }} disabled={downloadCliked}>
                                        <span className='indicator-label'>Download File</span>
                                    </button>
                                }
                                <button id='discard-button' type='button' className='btn btn-dark rounded-0 btn-sm col-4'
                                    onClick={() => closeModal()}>
                                    <span className='indicator-label'>Discard</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* end::Modal content */}
                </div>
                {/* end::Modal dialog */}
            </div>
            {/* begin::Modal Backdrop */}
            <div className='modal-backdrop fade show'></div>
            {/* end::Modal Backdrop */}
        </>}
        </>
    )
}

export default TransactionAPPViewform;
