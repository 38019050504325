import axios, { AxiosResponse } from 'axios'
import { SalaryStructureSetting } from './model'
import { ID, Response } from '_metronic/helpers'
import { API_URL } from '_azkatech/azkHelper'
import useFixServerClientData from 'app/utilities/fix-Server-Client-Data'
import { AZKHelper } from 'app/modules/components/azkHelper'
import moment from 'moment'

const MODEL_NAME = 'az.salary.structure.setting'
const URL = `${API_URL}/${MODEL_NAME}`
const GET_URL = `${API_URL}/${MODEL_NAME}/`
const INCLUDE_FIELDS = `'id', 'name', 'az_country_id', 'brand_id', 'location_id', 'job_id', ('grade_id',('id', 'grade_name')), 'person_type', 'employment_category_id', 'package', 'has_com', 'com', 'as_effective_date', 'is_service_charge', 'applied', 'business_type', 'is_editable', 'trigger_compute'`

// const getSalaryStructureSetting = (domain: string): Promise<SalaryStructureSettingQueryResponse> => {
//   return axios
//     .get(`${GET_URL}?filters=[${domain}]`)
//     .then((d: AxiosResponse<SalaryStructureSettingQueryResponse>) => d.data)
// }

const GetSalaryStructureSettingByFilter = (country_id: number, brand_id: number, as_effective_date:string, location_id?: number, job_id?: number,
  employment_category_id?: number, grade_id?: number, person_type?: string): Promise<SalaryStructureSetting[]> => {
  let filters = `('az_country_id','=', ${country_id}), ('brand_id','=', ${brand_id})`
  if (job_id) {
    filters = filters.concat(`, ('job_id','=', ${job_id})`)
  } 
  if (employment_category_id) {
    filters = filters.concat(`, ('employment_category_id','=', ${employment_category_id})`)
  } 
  if (grade_id) {
    filters = filters.concat(`, ('grade_id','=', ${grade_id})`)
  } 
  if (person_type) {
    let person_type_encoded = encodeURIComponent(person_type)
    filters = filters.concat(`, ('person_type','ilike', '${person_type_encoded}')`)
  }
  if (location_id) {
    filters = filters.concat(`, ('location_id','=', ${location_id})`)
  } else {
    filters = filters.concat(`, ('location_id','=', False)`)
  }
  if (as_effective_date) {
    const server_as_effective_date = moment(as_effective_date).utc(true)?.format(AZKHelper.BACKEND_DATE_FORMAT)
    filters = filters.concat(`, ('as_effective_date','<=', '${server_as_effective_date}')`)
  }
  const { FixServerArrayData} = useFixServerClientData();
  return axios
    .get(`${URL}?filters=[${filters}]&include_fields=[${INCLUDE_FIELDS}]&order='as_effective_date desc'`)
    .then((response: AxiosResponse<Response<SalaryStructureSetting[]>>) => response.data)
    .then((response: Response<SalaryStructureSetting[]>) => FixServerArrayData(response['results']))
}

const GetSalaryStructureSettingById = (id: ID): Promise<SalaryStructureSetting> => {
  const { FixServerData} = useFixServerClientData();
  return axios
    .get(`${URL}?filters=[('id','=',${id})]&include_fields=[${INCLUDE_FIELDS}]`)
    .then((response: AxiosResponse<Response<SalaryStructureSetting>>) => response.data)
    .then((response: Response<SalaryStructureSetting>) => FixServerData(response['results'][0]))
}

const CreateSalaryStructureSetting = async (transaction: SalaryStructureSetting): Promise<SalaryStructureSetting | undefined> => {
  const { FixClientData} = useFixServerClientData();
  const shallowTransaction = await FixClientData(transaction)
  return axios
    .post(URL, shallowTransaction)
    .then((response: AxiosResponse<Response<SalaryStructureSetting>>) => response.data)
    .then((response: Response<SalaryStructureSetting>) => response['results'])
}

const UpdateSalaryStructureSetting = async (transaction: SalaryStructureSetting): Promise<SalaryStructureSetting | undefined> => {
  const { FixClientData} = useFixServerClientData();
  const shallowTransaction = await FixClientData(transaction)
  return axios
    .put(`${URL}/${transaction.id}`, shallowTransaction)
    .then((response: AxiosResponse<Response<SalaryStructureSetting>>) => response.data)
    .then((response: Response<SalaryStructureSetting>) => response.data)
}

const DeleteSalaryStructureSetting = (transactionId: number): Promise<SalaryStructureSetting | undefined> => {
  return axios
    .delete(`${URL}/${transactionId}`)
    .then((response: AxiosResponse<Response<SalaryStructureSetting>>) => response.data)
    .then((response: Response<SalaryStructureSetting>) => response.data)
}

export { GetSalaryStructureSettingById, CreateSalaryStructureSetting, UpdateSalaryStructureSetting, MODEL_NAME, GetSalaryStructureSettingByFilter, DeleteSalaryStructureSetting}